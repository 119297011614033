;(function() {

	let animateSection = document.querySelectorAll( '[data-scroll]' );

	let observerSection = new IntersectionObserver( ( entries, observer ) => {
		entries.forEach(entry => {
			if (entry.intersectionRatio > 0) {
				// console.log( entry );
				entry.target.classList.add( entry.target.dataset.scroll, entry.target.dataset.delay, entry.target.dataset.duration );
			}
		} );
	}, {
		root: null,
		rootMargin: '100px',
		threshold: 0
	} );

	animateSection.forEach(section => {
		observerSection.observe( section );
	} );

})();
